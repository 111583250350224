import React from 'react';
import TemplateInformPage from "../../../../Components/TemplateInformPage";
import {Localization} from "../../../../Common/Localization";
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
    },
};

function EmailVerificationSuccessPage() {
  return (
      <div style={{...styles.container}}>
        <TemplateInformPage
        title={Localization.VERIFY_PAGE.SUCCESS.TITLE}
        text={Localization.VERIFY_PAGE.SUCCESS.MESSAGE}
        />
          <div style={{height: 50}}></div>

      </div>
  );
}

export default EmailVerificationSuccessPage;

import React, { Component } from 'react';

import SetNewPasswordContainer from './Routes/ResetPassword/ResetPasswordContainer';
import SuccessPage from './Routes/Success/SuccessPage';
import SessionExpiredPage from './Routes/SessionExpired/SessionExpiredPage';
import { config } from './Config/Config';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { PathsEnum } from './Common/PathsEnum';
import EmailVerificationContainer from './Routes/ResetPassword/EmailVerificationContainer';
import EmailVerificationSuccessPage
  from "./Routes/ResetPassword/EmailVerificationContainer/EmailVerificationSuccessPage";
import EmailVerificationFailurePage
  from "./Routes/ResetPassword/EmailVerificationContainer/EmailVerificationFailurePage";
import {createTheme, ThemeProvider} from "@mui/material";

const styles = {
  routesContainer: {
    width: '100%',
    height: 'calc(100% - 40px)',
    justifyContent: 'center',
    display: 'flex',
  },
  header: {
    height: '40px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  background: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
};

function Header() {
  return (
    <div style={{ ...styles.header, backgroundColor: config.theme.headerBackgroundColor }}>
      {!config.shouldDisplayLogoAsProjectName ? (
        <img alt={'logo'} src={'/logo.svg'} />
      ) : (
        <label style={config.theme.projectNameStyle}>{config.projectName}</label>
      )}
    </div>
  );
}

function Background({ children }) {
  return (
    <div style={{ ...styles.background, background: config.theme.background }}>{children}</div>
  );
}
const theme = createTheme({});

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Background>
          {!config.useCustomHtml &&
              <Header/>
          }
          <Router>
            <div style={styles.routesContainer}>
              <Route exact path={PathsEnum.MAIN} component={SetNewPasswordContainer} />
              <Route
                exact
                path={PathsEnum.RESET_PASSWORD_ROOT}
                component={SetNewPasswordContainer}
              />
              <Route
                exact
                path={PathsEnum.EMAIL_VERIFICATION_PAGE}
                component={EmailVerificationContainer}
              />
              <Route exact path={PathsEnum.EMAIL_VERIFICATION_SUCCESS_PAGE} component={EmailVerificationSuccessPage} />
              <Route exact path={PathsEnum.EMAIL_VERIFICATION_FAILURE_PAGE} component={EmailVerificationFailurePage} />
              <Route exact path={PathsEnum.SUCCESS_PAGE} component={SuccessPage} />
              <Route exact path={PathsEnum.SESSION_EXPIRED_PAGE} component={SessionExpiredPage} />
            </div>
          </Router>
        </Background>
      </ThemeProvider>
    );
  }
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from 'axios';
import { initConfig } from './Config/Config';
import registerServiceWorker from './registerServiceWorker';

function renderApp() {
  ReactDOM.render(<App />, document.getElementById('root'));
}

(function init() {
  const CONFIGURATION_FILE = process.env.PUBLIC_URL + '/configuration.json';
  registerServiceWorker();
  axios
    .get(CONFIGURATION_FILE)
    .then(response => response.data)
    .then(initConfig)
    .then(renderApp);
})();

import axios from 'axios';
import { config } from './Config/Config';

const END_POINTS = {
  RESET_PASSWORD: '/v4/auth/resetPassword',
  GET_PASSWORD_POLICY: '/v4/auth/passwordPolicy',
  VERIFY_EMAIL: '/v4/auth/verifyEmail',
};

function resetPassword(token, newPassword) {
  const reqData = {
    identity: token,
    password: newPassword,
  };
  return axios.post(`${config.server}${END_POINTS.RESET_PASSWORD}`, reqData);
}

function verifyEmail(token) {
  return axios.post(`${config.server}${END_POINTS.VERIFY_EMAIL}`, {
    token: token,
  });
}

function getPasswordPolicy(userRole) {
  return axios.get(`${config.server}${END_POINTS.GET_PASSWORD_POLICY}?userRole=${userRole}`);
}

export const NetworkApi = {
  resetPassword,
  getPasswordPolicy,
  verifyEmail,
};

import { ValidationFunctionsUtils } from '../ValidationFunctionsUtils';
import format from 'string-template';

export const ServerPasswordPolicyRulesEnum = {
  MinimumLength: 'MinimumLength',
  MaximumLength: 'MaximumLength',
  MaximumConsecutiveIdentical: 'MaximumConsecutiveIdentical',
  Complexity_MinimumUpperCase: 'Complexity_MinimumUpperCase',
  Complexity_MinimumLowerCase: 'Complexity_MinimumLowerCase',
  Complexity_MinimumDigits: 'Complexity_MinimumDigits',
  Complexity_MinimumSpecialChars: 'Complexity_MinimumSpecialChars',
  RejectWhiteSpace: 'RejectWhiteSpace',
};

export const AppPasswordPolicyRulesEnum = {
  MINIMUM_LENGTH: 'MINIMUM_LENGTH',
  MAXIMUM_LENGTH: 'MAXIMUM_LENGTH',
  MAXIMUM_CONESCUTIVE_IDENTICAL: 'MAXIMUM_CONESCUTIVE_IDENTICAL',
  MINIMUM_UPPER_CASE: 'MINIMUM_UPPER_CASE',
  MINIMUM_LOWER_CASE: 'MINIMUM_LOWER_CASE',
  MINIMUM_DIGITS: 'MINIMUM_DIGITS',
  MINIMUM_SPECIAL_CHARS: 'MINIMUM_SPECIAL_CHARS',
  REJECT_WHITE_SPACE: 'REJECT_WHITE_SPACE',
};

export function mappingAppRulesToServerRules(rule) {
  switch (rule) {
    case ServerPasswordPolicyRulesEnum.MinimumLength: {
      return AppPasswordPolicyRulesEnum.MINIMUM_LENGTH;
    }
    case ServerPasswordPolicyRulesEnum.MaximumLength: {
      return AppPasswordPolicyRulesEnum.MAXIMUM_LENGTH;
    }
    case ServerPasswordPolicyRulesEnum.MaximumConsecutiveIdentical: {
      return AppPasswordPolicyRulesEnum.MAXIMUM_CONESCUTIVE_IDENTICAL;
    }
    case ServerPasswordPolicyRulesEnum.Complexity_MinimumUpperCase: {
      return AppPasswordPolicyRulesEnum.MINIMUM_UPPER_CASE;
    }
    case ServerPasswordPolicyRulesEnum.Complexity_MinimumLowerCase: {
      return AppPasswordPolicyRulesEnum.MINIMUM_LOWER_CASE;
    }
    case ServerPasswordPolicyRulesEnum.Complexity_MinimumDigits: {
      return AppPasswordPolicyRulesEnum.MINIMUM_DIGITS;
    }
    case ServerPasswordPolicyRulesEnum.Complexity_MinimumSpecialChars: {
      return AppPasswordPolicyRulesEnum.MINIMUM_SPECIAL_CHARS;
    }
    case ServerPasswordPolicyRulesEnum.RejectWhiteSpace: {
      return AppPasswordPolicyRulesEnum.REJECT_WHITE_SPACE;
    }
    default: {
      return null;
    }
  }
}

function validateRejectWhiteSpaces(value, shouldRejectWhiteSpaces) {
  if (shouldRejectWhiteSpaces) {
    return !ValidationFunctionsUtils.isContainWhiteSpaces(value);
  } else {
    return ValidationFunctionsUtils.isContainWhiteSpaces(value);
  }
}

const mappingRuleIdToFunc = {
  [AppPasswordPolicyRulesEnum.MINIMUM_LENGTH]: ValidationFunctionsUtils.isMinimumLength,
  [AppPasswordPolicyRulesEnum.MAXIMUM_LENGTH]: ValidationFunctionsUtils.isMaximumLength,
  [AppPasswordPolicyRulesEnum.MAXIMUM_CONESCUTIVE_IDENTICAL]:
    ValidationFunctionsUtils.isConsecutiveIdenticalCharacters,
  [AppPasswordPolicyRulesEnum.MINIMUM_UPPER_CASE]: ValidationFunctionsUtils.isHaveMinimumUpperCase,
  [AppPasswordPolicyRulesEnum.MINIMUM_LOWER_CASE]: ValidationFunctionsUtils.isHaveMinimumLowerCase,
  [AppPasswordPolicyRulesEnum.MINIMUM_DIGITS]: ValidationFunctionsUtils.isHaveMinimumDigits,
  [AppPasswordPolicyRulesEnum.REJECT_WHITE_SPACE]: validateRejectWhiteSpaces,
};

export const mappingRuleIdToError = {
  [AppPasswordPolicyRulesEnum.MINIMUM_LENGTH]: 'Password must contain at least {0} characters',
  [AppPasswordPolicyRulesEnum.MAXIMUM_LENGTH]: 'Password must contain at most {0} characters',
  [AppPasswordPolicyRulesEnum.MAXIMUM_CONESCUTIVE_IDENTICAL]:
    'Password cannot have more than {0} consecutive characters',
  [AppPasswordPolicyRulesEnum.MINIMUM_UPPER_CASE]:
    'There should be at least {0} upper case characters',
  [AppPasswordPolicyRulesEnum.MINIMUM_LOWER_CASE]:
    'There should be at least {0} lower case characters',
  [AppPasswordPolicyRulesEnum.MINIMUM_DIGITS]: 'There should be at least {0} digits',
  [AppPasswordPolicyRulesEnum.REJECT_WHITE_SPACE]: 'Password cannot contain whitespace',
};

export function createPasswordPolicyValidationArray(rules) {
  const array = [];
  Object.keys(rules).forEach(key => {
    if (!(key in AppPasswordPolicyRulesEnum)) {
      console.warn('Unknown validation rule: ', key);
      return;
    } else {
      array.push(value => {
        return mappingRuleIdToFunc[AppPasswordPolicyRulesEnum[key]](value, rules[key])
          ? undefined
          : format(mappingRuleIdToError[AppPasswordPolicyRulesEnum[key]], rules[key]);
      });
    }
  });

  return array;
}

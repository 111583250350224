import React from 'react';
import TemplateInformPage from "../../../../Components/TemplateInformPage";
import {Localization} from "../../../../Common/Localization";
import format from "string-template";
import {config} from "../../../../Config/Config";
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
    },
};

function EmailVerificationFailurePage() {
  return (
      <div style={{...styles.container}}>
        <TemplateInformPage
          title={Localization.VERIFY_PAGE.ERROR.TITLE}
          text={format(Localization.VERIFY_PAGE.ERROR.MESSAGE, config.projectName)}
        />
          <div style={{height: 50}}></div>
      </div>
  );
}

export default EmailVerificationFailurePage;

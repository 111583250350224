export const Localization = {
  FORM_TITLE: 'Reset Password',
  PASSWORD_INPUT_FLOATING_LABEL_TEXT: 'New Password',
  CONFIRM_PASSWORD_INPUT_ERROR_TEXT: 'Passwords do not match',
  CONFIRM_PASSWORD_INPUT_FLOATING_LABEL_TEXT: 'Confirm New Password',
  SUBMIT_BUTTON_TEXT: 'Save',
  SESSION_EXPIRED_TITLE: 'Oops,',
  SESSION_EXPIRED_TEXT: `This reset password link can be used within one hour, or has already been used. Please try resetting your password again, on the {0} application.`,
  SUCCESS_PAGE_TITLE: 'Success',
  SUCCESS_PAGE_TEXT: 'Password changed successfully',
  GENERAL_ERROR_API: 'There was a problem with the request, please try again later',
  VERIFY_PAGE: {
    REDIRECTION: {
      TITLE: 'Verification in Progress',
      MESSAGE: 'You will be automatically redirected.',
    },
    SUCCESS: {
      TITLE: 'Email verified',
      MESSAGE: 'Your email has been successfully verified.',
    },
    ERROR: {
      TITLE: 'Something went wrong',
      MESSAGE: 'Try verifying your email again on the {0} application.',
    },
  },
};

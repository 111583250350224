import React from 'react';
import { config } from '../../Config/Config';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
  },
};

function TemplateInformPage(props) {
  const titleStyle = {
    fontSize: '20px',
    ...config.theme.informPageTitle,
  };
  const textStyle = {
    textAlign: 'center',
    marginTop: '20px',
    ...config.theme.informPageText,
  };

  return (
    <div style={styles.container}>
      <div style={titleStyle}>{props.title}</div>
      <div style={textStyle}>{props.text}</div>
    </div>
  );
}

export default TemplateInformPage;

import React from 'react';
import TemplateInformPage from '../../../Components/TemplateInformPage';
import { Localization } from '../../../Common/Localization';
import format from 'string-template';
import { config } from '../../../Config/Config';

function SessionExpiredPage() {
  if (config.useCustomHtml) {
    return  <iframe style={{width:'100%', height:'calc(100% + 40px)'}} src={process.env.PUBLIC_URL + "/CustomHtml/sessionExpire.html"}></iframe>
  }
  return (
    <TemplateInformPage
      title={Localization.SESSION_EXPIRED_TITLE}
      text={format(Localization.SESSION_EXPIRED_TEXT, config.projectName)}
    />
  );
}

export default SessionExpiredPage;

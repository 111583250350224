import React from 'react';
import { config } from '../../Config/Config';
import {CircularProgress} from "@mui/material";

const styles = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.5',
  },
};

function Spinner() {
  return (
    <div style={styles.container}>
      <CircularProgress size={50} thickness={4} style={{color: config.theme.spinnerColor} }  />
    </div>
  );
}

export default Spinner;

import React, {useState} from 'react';

import { config } from '../../Config/Config';
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {VisibilityOffOutlined, VisibilityOutlined} from '@mui/icons-material';
function PasswordInput(props) {

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = (e) => {setShowPassword(!showPassword)};
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

  return (
    <TextField
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onChange={(e) => props.onChange(e, e.target.value)}
      value={props.value}
      error={!!props.errorText}
      label={props.floatingLabelText}
      helperText={props.errorText}
      sx={{width: '256px',
          '& .MuiInput-underline:after': { borderBottomColor: props.errorText ? config.theme.inputErrorStyle?.color : config.theme.inputUnderlineFocus?.borderColor },
          '& .MuiInput-underline:before': props.errorText ? { borderBottomColor: config.theme.inputErrorStyle?.color } : undefined,
          "& .MuiFormHelperText-root, & .Mui-error.MuiFormHelperText-root": config.theme.inputErrorStyle,
          }}
      variant={'standard'}
      InputLabelProps={{style: config.theme.inputFloatingLabel }}
      type={showPassword ? "text" : "password"}
      InputProps={{
          style: {...config.theme.inputStyle},
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="small">
                    {showPassword ? (
                        <VisibilityOutlined style={{fontSize: '18px'}}/>
                    ) : (
                        <VisibilityOffOutlined style={{fontSize: '18px'}}/>
                    )}
                </IconButton>
            </InputAdornment>
            )}}
    />
  );
}

export default PasswordInput;

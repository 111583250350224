import React from 'react';
import TemplateInformPage from '../../../Components/TemplateInformPage';
import { Localization } from '../../../Common/Localization';
import {config} from "../../../Config/Config";

function SuccessPage() {
  return (
      config.useCustomHtml?
          <iframe style={{width:'100%', height:'calc(100% + 40px)'}} src={process.env.PUBLIC_URL + "/CustomHtml/success.html"}></iframe>:
    <TemplateInformPage
      title={Localization.SUCCESS_PAGE_TITLE}
      text={Localization.SUCCESS_PAGE_TEXT}
    />
  );
}

export default SuccessPage;

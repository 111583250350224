import React, { Component } from 'react';
import { Localization } from '../../Common/Localization';
import { config } from '../../Config/Config';
import {Box} from "@mui/material";

const styles = {
  container: {
    borderRadius: '43px',
    width: '270px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};

class EllipseButton extends Component {
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (!this.props.isDisabled) {
      this.props.onClick();
    }
  }

  render() {
    const containerStyle = {
      ...styles.container,
      ...config.theme.saveButtonStyle,
      ...(this.props.isDisabled ? {
        cursor: 'default',
        ...config.theme.saveButtonDisableStyle,
      } : undefined),
      ':active': this.props.isDisabled ? {} : {
        ...config.theme.saveButtonPressedStyle,
      },
    };

    const labelStyle = {
      userSelect: 'none',
      ...config.theme.saveButtonLabel,
    };

    return (
      <Box disabled={this.props.isDisabled} sx={containerStyle} onClick={this.onClick}>
        <Box sx={labelStyle}>{Localization.SUBMIT_BUTTON_TEXT}</Box>
      </Box>
    );
  }
}

export default EllipseButton;

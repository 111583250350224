import React, { Component } from 'react';
import PasswordInput from '../../../Components/PasswordInput/index';
import EllipseButton from '../../../Components/EllipseButton/index';
import { config } from '../../../Config/Config';
import { Localization } from '../../../Common/Localization';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '100px',
    paddingBottom: '100px',
    justifyContent: 'space-between',
    position: 'relative',
  },
  errorText: {
    maxWidth: '220px',
    textAlign: 'center',
    marginTop: '20%',
    alignSelf: 'center',
    display: 'flex',
  },
};

function Title() {
  return <div style={config.theme.formTitle}>{Localization.FORM_TITLE}</div>;
}

function isConfirmPasswordInputValid(pass, confirmPass) {
  return pass === confirmPass;
}

class ResetPasswordForm extends Component {
  constructor() {
    super();

    this.state = {
      passwordInputValue: '',
      passwordInputErrorText: '',
      confirmPasswordInputValue: '',
      confirmPasswordInputErrorText: '',
      isSubmitDisable: true,
    };

    this.onPasswordInputBlur = this.onPasswordInputBlur.bind(this);
    this.onPasswordInputFocus = this.onPasswordInputFocus.bind(this);
    this.onPasswordInputChange = this.onPasswordInputChange.bind(this);
    this.onConfirmPasswordInputBlur = this.onConfirmPasswordInputBlur.bind(this);
    this.onConfirmPasswordInputFocus = this.onConfirmPasswordInputFocus.bind(this);
    this.onConfirmPasswordInputChange = this.onConfirmPasswordInputChange.bind(this);
    this.getErrorMessagesFromValidations = this.getErrorMessagesFromValidations.bind(this);
    this.isPasswordInputValid = this.isPasswordInputValid.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getErrorMessagesFromValidations(value) {
    return this.props.validationsArray.map(validationFunc => validationFunc(value)).filter(Boolean);
  }

  isPasswordInputValid(value) {
    return this.getErrorMessagesFromValidations(value).length === 0;
  }

  onPasswordInputBlur() {
    const errorsMessagesArray = this.getErrorMessagesFromValidations(this.state.passwordInputValue);

    if (errorsMessagesArray.length > 0) {
      this.setState({ passwordInputErrorText: errorsMessagesArray[0] });
    }
  }

  onPasswordInputFocus() {
    this.setState({ passwordInputErrorText: '' });
  }

  onPasswordInputChange(event, newValue) {
    this.setState({
      passwordInputValue: newValue,
      isSubmitDisable:
        !this.isPasswordInputValid(newValue) ||
        !isConfirmPasswordInputValid(newValue, this.state.confirmPasswordInputValue),
    });
  }

  onConfirmPasswordInputBlur() {
    if (
      !isConfirmPasswordInputValid(
        this.state.passwordInputValue,
        this.state.confirmPasswordInputValue
      )
    ) {
      this.setState({
        confirmPasswordInputErrorText: Localization.CONFIRM_PASSWORD_INPUT_ERROR_TEXT,
      });
    }
  }

  onConfirmPasswordInputFocus() {
    this.setState({ confirmPasswordInputErrorText: '' });
  }

  onConfirmPasswordInputChange(event, newValue) {
    this.setState({
      confirmPasswordInputValue: newValue,
      isSubmitDisable:
        !this.isPasswordInputValid(this.state.passwordInputValue) ||
        !isConfirmPasswordInputValid(this.state.passwordInputValue, newValue),
    });
  }

  onSubmit() {
    this.props.onSubmit(this.state.passwordInputValue);
  }

  render() {
    return (
      <div style={styles.container}>
        <Title />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PasswordInput
            value={this.state.passwordInputValue}
            onBlur={this.onPasswordInputBlur}
            onFocus={this.onPasswordInputFocus}
            onChange={this.onPasswordInputChange}
            errorText={this.state.passwordInputErrorText}
            floatingLabelText={Localization.PASSWORD_INPUT_FLOATING_LABEL_TEXT}
          />
          <div style={{ marginTop: '8px' }}>
            <PasswordInput
              onBlur={this.onConfirmPasswordInputBlur}
              onFocus={this.onConfirmPasswordInputFocus}
              onChange={this.onConfirmPasswordInputChange}
              value={this.state.confirmPasswordInputValue}
              errorText={this.state.confirmPasswordInputErrorText}
              floatingLabelText={Localization.CONFIRM_PASSWORD_INPUT_FLOATING_LABEL_TEXT}
            />
          </div>
          <div
            style={{
              ...styles.errorText,
              ...config.theme.formErrorText,
            }}>
            {this.props.errorText}
          </div>
        </div>
        <EllipseButton isDisabled={this.state.isSubmitDisable} onClick={this.onSubmit} />
      </div>
    );
  }
}

export default ResetPasswordForm;

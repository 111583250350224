export const config = {
  server: '',
  projectName: '',
  shouldDisplayLogoAsProjectName: '',
  useCustomHtml: false,
  redirectUrlAfterEmailVerification: {
    success: {
      desktop: '',
      mobile: ''
    },
    failure: {
      desktop: '',
      mobile: ''
    }
  },
  theme: {
    background: '',
    headerBackgroundColor: '',
    projectNameStyle: {},
    saveButtonStyle: {},
    saveButtonDisableStyle: {},
    saveButtonPressedStyle: {},
    saveButtonLabel: {},
    inputFloatingLabel: {},
    inputErrorStyle: {},
    inputUnderlineFocus: {},
    inputStyle: {},
    spinnerColor: '',
    informPageTitle: {},
    informPageText: {},
    formErrorText: {},
    formTitle: {},
  },
};

export const initConfig = function(settings) {
  config.server = settings.server;
  config.projectName = settings.projectName;
  config.shouldDisplayLogoAsProjectName = settings.shouldDisplayLogoAsProjectName;
  config.redirectUrlAfterEmailVerification = settings.redirectUrlAfterEmailVerification;
  config.useCustomHtml = settings.useCustomHtml;
  config.theme = settings.theme;
};

function isMinimumLength(value, minimumLength) {
  return value.length >= minimumLength;
}

function isMaximumLength(value, maximumLength) {
  return value.length <= maximumLength;
}

function isConsecutiveIdenticalCharacters(value, maxConsecutive) {
  let regex;
  if (maxConsecutive > 1) {
    regex = new RegExp(`(.)\\1{${maxConsecutive}}`);
  } else {
    regex = new RegExp('(.)\\1');
  }

  return !Boolean(value.match(regex));
}

function isHaveMinimumOfPattern(value, minimum, regexPattern) {
  const matches = value.match(regexPattern);

  if (matches) {
    return matches.length >= minimum;
  } else {
    return false;
  }
}

function isHaveMinimumUpperCase(value, minimumUpper) {
  return isHaveMinimumOfPattern(value, minimumUpper, new RegExp('[A-Z]'));
}

function isHaveMinimumLowerCase(value, minimumLower) {
  return isHaveMinimumOfPattern(value, minimumLower, new RegExp('[a-z]'));
}

function isHaveMinimumDigits(value, minimumDigits) {
  return isHaveMinimumOfPattern(value, minimumDigits, new RegExp('[0-9]'));
}

function isContainWhiteSpaces(value) {
  return Boolean(value.match('\\s+'));
}

export const ValidationFunctionsUtils = {
  isMinimumLength,
  isMaximumLength,
  isConsecutiveIdenticalCharacters,
  isHaveMinimumUpperCase,
  isHaveMinimumLowerCase,
  isHaveMinimumDigits,
  isContainWhiteSpaces,
};

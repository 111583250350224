import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Spinner from '../../../Components/Spinner/index';
import queryString from 'query-string';
import { NetworkApi } from '../../../NetworkApi';
import TemplateInformPage from '../../../Components/TemplateInformPage';
import format from 'string-template';
import { Localization } from '../../../Common/Localization';
import { config } from '../../../Config/Config';
import get from "lodash.get";
import { isMobile } from 'react-device-detect';

const styles = {
  container: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  redirectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },

  spinnerWrapper: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.2)',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
};

class EmailVerificationContainer extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      title: '',
      text: '',
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const token = params.token;
    this.doVerifyEmail(token);
  }

  hasRedirectionData() {
    return get(config, 'redirectUrlAfterEmailVerification.success.desktop')
        || get(config, 'redirectUrlAfterEmailVerification.success.mobile')
        || get(config, 'redirectUrlAfterEmailVerification.failure.desktop')
        || get(config, 'redirectUrlAfterEmailVerification.failure.mobile')
  }

  getRedirectionUrl(success) {
    const successOrFailurePath = success ? 'success' : 'failure';
    const deviceTypePath = isMobile ? 'mobile' : 'desktop';
    return get(config,`redirectUrlAfterEmailVerification.${successOrFailurePath}.${deviceTypePath}`);
  }

  handleSuccessRedirection() {
    const redirectionUrl = this.getRedirectionUrl(true);
    if (redirectionUrl) {
      window.location.href = redirectionUrl;
    } else {
      this.props.history.replace('/verify-email-success');
    }
  }

  handleFailureRedirection() {
    const redirectionUrl = this.getRedirectionUrl(false);
    if (redirectionUrl) {
      window.location.href = redirectionUrl;
    } else {
      this.props.history.replace('/verify-email-failure');
    }
  }

  doVerifyEmail(token) {
    NetworkApi.verifyEmail(token)
      .then(() => {
        if (this.hasRedirectionData()) {
          this.handleSuccessRedirection()
        } else {
          this.setState({
            isLoading: false,
            title: Localization.VERIFY_PAGE.SUCCESS.TITLE,
            text: Localization.VERIFY_PAGE.SUCCESS.MESSAGE,
          });
        }
      })
      .catch(() => {
        if (this.hasRedirectionData()) {
          this.handleFailureRedirection();
        } else {
          this.setState({
          isLoading: false,
          title: Localization.VERIFY_PAGE.ERROR.TITLE,
          text: format(Localization.VERIFY_PAGE.ERROR.MESSAGE, config.projectName),
          });
        }
      });
  }

  render() {
    if (this.hasRedirectionData()) {
      return (
          <div style={styles.redirectionContainer}>
            <TemplateInformPage title={Localization.VERIFY_PAGE.REDIRECTION.TITLE} text={Localization.VERIFY_PAGE.REDIRECTION.MESSAGE}  />
            <div style={{height: 50}}><Spinner /></div>
          </div>
      );
    }
    return (
      <div style={styles.container}>
        {this.state.isLoading ? (
          <div style={styles.spinnerWrapper}>
            <Spinner />
          </div>
        ) : (
          <TemplateInformPage title={this.state.title} text={this.state.text} />
        )}
      </div>
    );
  }
}

export default withRouter(EmailVerificationContainer);

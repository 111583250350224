import React, { Component } from 'react';
import { withRouter } from 'react-router';
import SetNewPasswordForm from '../ResetPasswordForm/index';
import Spinner from '../../../Components/Spinner/index';
import queryString from 'query-string';
import { Localization } from '../../../Common/Localization';
import { Consts } from '../../../Common/Consts';
import { NetworkApi } from '../../../NetworkApi';
import { PathsEnum } from '../../../Common/PathsEnum';
import {config} from "../../../Config/Config";
import get from 'lodash.get';
import mapKeys from 'lodash.mapkeys';
import {
  createPasswordPolicyValidationArray,
  mappingAppRulesToServerRules,
} from '../../../Common/PasswordPolicy/PasswordPolicy';

const styles = {
  container: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  spinnerWrapper: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.2)',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
};

class ResetPasswordContainer extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      passwordPolicyValidationsArray: [],
    };

    this.onSaveClicked = this.onSaveClicked.bind(this);
    this.doFetchPasswordPolicy = this.doFetchPasswordPolicy.bind(this);
    this.onCustomHtmlSaveClicked = this.onCustomHtmlSaveClicked.bind(this);
    window.onCustomHtmlSaveClicked = this.onCustomHtmlSaveClicked;
  }

   onCustomHtmlSaveClicked(newPassword){
     const errorMessagesFromValidations =  this.state.passwordPolicyValidationsArray.map(validationFunc => validationFunc(newPassword)).filter(Boolean);
     if (errorMessagesFromValidations.length > 0) {
       const iframe = document.getElementById("customHtmlIframe");
       iframe.contentWindow.postMessage({ type: "changeValue", value: errorMessagesFromValidations[0] }, "*");
     } else {
       this.onSaveClicked(newPassword);
     }
  }

  componentDidMount() {
    try {
      const params = queryString.parse(this.props.location.search);
      this.doFetchPasswordPolicy("APPUSER");
    } catch (e) {
      this.props.history.replace(PathsEnum.SESSION_EXPIRED_PAGE);
    }
  }

  onSaveClicked(newPass) {
    const params = queryString.parse(this.props.location.search);
    const token = params.token;

    this.setState({ isLoading: true });
      NetworkApi.resetPassword(token, newPass)
          .then(() => {
              this.props.history.replace(PathsEnum.SUCCESS_PAGE);
          })
          .catch(err => {
              const errorCode = get(err, 'response.data.errorCode', 0);

              if (errorCode === Consts.INVALID_TOKEN_CODE) {
                  this.props.history.replace(PathsEnum.SESSION_EXPIRED_PAGE);
              } else {
                  if (config.useCustomHtml) {
                      const iframe = document.getElementById("customHtmlIframe");
                      iframe.contentWindow.postMessage({
                          type: "changeValue",
                          value: Localization.GENERAL_ERROR_API
                      }, "*");
                  }
                  this.setState({
                      errorText: Localization.GENERAL_ERROR_API,
                      isLoading: false,
                  });

              }
          });
  }

  doFetchPasswordPolicy(userRole) {
    return NetworkApi.getPasswordPolicy(userRole).then(res => {
      const validationsArray = createPasswordPolicyValidationArray(
        mapKeys(res.data.rules, (value, key) => mappingAppRulesToServerRules(key))
      );

      this.setState({ passwordPolicyValidationsArray: validationsArray });
    });
  }

  render() {

    return (
      <div style={styles.container}>
        {config.useCustomHtml ?
            <iframe id={"customHtmlIframe"}  style={{width:'100%', height:'calc(100% + 40px)'}}
                    src={process.env.PUBLIC_URL + "/CustomHtml/resetPassword.html"}></iframe>
            :
            <SetNewPasswordForm
                onSubmit={this.onSaveClicked}
                errorText={this.state.errorText}
                validationsArray={this.state.passwordPolicyValidationsArray}/>
        }
        {this.state.isLoading && (
          <div style={styles.spinnerWrapper}>
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ResetPasswordContainer);
